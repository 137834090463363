// Customizations Here (Optional)

@charset "utf-8";

// Import a Google Font
// @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");

// Set your brand colors

// @font-face {
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 400;
//   src: local("Roboto-Regular"),
//     url("../assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
// }

$purple: #8a4d76;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
// // Update Bulma's global variables
$family-sans-serif: "Nunito", BlinkMacSystemFont, -apple-system, "Segoe UI",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  "Helvetica", "Arial", sans-serif;

// $table-cell-border-width: 0;
$table-cell-border: 0;
// $table-cell-padding: 0.5em 0.25em;
// $grey-dark: $brown;
// $grey-light: $beige-light;
// $primary: $purple;
// $link: $pink;
// $widescreen-enabled: false;
// $fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
// $control-border-width: 2px;
// $input-border-color: transparent;
// $input-shadow: none;

$card-shadow: none;
$card-header-shadow: none;

// Bulma Import
@import "./node_modules/bulma/bulma.sass";
@import "blocklist";
@import "switch";
@import "checkradio";

@import "pageloader"; // main .section {
//   margin-top: 1rem;
// }

// $nav-height: 3rem;

// .top-nav {
//   position: fixed;
//   top: 0;
//   left: 0;
//   z-index: 1000 !important;
//   width: 100%;
//   background: white;
//   height: $nav-height;
// }

// .left-button {
//   max-width: 100px;
//   width: 100%;
//   height: $nav-height;
//   border-radius: 0;
//   text-decoration: none !important;
// }

// .app-title {
//   text-align: center;
//   line-height: $nav-height;
// }

body {
  // background: red;
}
